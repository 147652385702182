@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
.get-in-touch-dialog-paper{padding:1.25rem !important;max-width:500px !important}#get-in-touch-dialog-title,#get-in-touch-dialog-content,#get-in-touch-dialog-actions{padding:1rem}#get-in-touch-dialog-title{padding-bottom:0}#get-in-touch-title{font-size:2.25rem}#channels-wrapper{display:flex;flex-wrap:wrap;justify-content:space-around;margin:1.5rem 0;margin-top:1rem}#channels-wrapper .channel-box-wrapper{display:inline-flex;flex-direction:column;align-items:center}#channels-wrapper .channel-link-icon{width:44px;height:44px;margin-right:.75rem;margin-left:.75rem;margin-top:.75rem}#get-in-touch-dialog-actions{display:flex;justify-content:flex-end}#get-in-touch-dialog-actions #go-back-button{min-height:44px !important;min-width:calc(3 * 44px) !important}@media (max-width: 530px){#channels-wrapper{flex-wrap:wrap}}@media (max-width: 415px){#get-in-touch-title{font-size:2rem}#get-in-touch-dialog-content{padding-bottom:0}.channel-box-wrapper{min-width:50%}#get-in-touch-dialog-actions{padding-top:0}#go-back-button{width:100%}}@media (max-width: 345px){#get-in-touch-title{font-size:1.5rem}}

#app-bar{position:fixed !important;width:800px !important;margin-left:calc(-20px);z-index:100;box-sizing:border-box;height:65px;padding-right:inherit;padding-left:inherit;display:flex;flex-direction:row;align-items:center;justify-content:space-between;background-color:white;top:-25px;opacity:0.2;-webkit-transition:top .7s ease-out,opacity .7s;transition:top .7s ease-out,opacity .7s}#app-bar.visible{top:0;opacity:1}#app-bar #my-headshot{height:80% !important;width:auto;border-radius:50% !important}#app-bar #nav-and-button-wrapper{display:flex;min-height:44px}#app-bar #nav-bar{display:flex;align-content:center}#app-bar #nav-bar .nav-bar-link{color:#212121;text-decoration:none !important;border-radius:16px;height:44px !important;min-width:44px;margin-right:.5rem;padding-right:1rem;padding-left:1rem;display:flex;flex-direction:column;justify-content:center;align-items:center}#app-bar #nav-bar .nav-bar-link:hover{background-color:#f5f5f7}#app-bar #nav-bar .nav-bar-link>*{font-weight:bold !important}#app-bar #nav-bar .nav-bar-link.active-nav-bar-link{color:#5846f6;background-color:#f5f5f7}#app-bar #menu-icon-wrapper{display:none;padding:0 !important;margin-left:.5rem}#app-bar #menu-icon{padding:.5rem}@media screen and (max-width: 800px){#app-bar{width:100% !important}}@media screen and (max-width: 570px){#nav-bar-background{background-color:transparent;width:100%;height:100vh;position:absolute;top:0;left:0;right:0;z-index:calc(100 - 1)}#menu-icon-wrapper{display:block !important;z-index:100}#nav-bar{z-index:100;left:-570px;position:absolute;top:65px !important;flex-direction:column;background-color:white;width:100%;-webkit-transition:left .7s;transition:left .7s}#nav-bar.expand-nav-bar{left:0px}#nav-bar .nav-bar-link{border-radius:0 !important;margin:0 !important;border-bottom:1px solid #f5f5f7}#nav-bar .nav-bar-link:first-child{border-top:1px solid #f5f5f7}#nav-bar .nav-bar-link .nav-bar-link-text{box-sizing:border-box;width:100%;text-align:right;padding-right:.5rem}}

#updates-wrapper #update-entries-wrapper .update-entry-section{padding:20px 10px;border-bottom:2px solid #f5f5f7}#updates-wrapper #update-entries-wrapper .update-entry-section .update-entry-title{font-size:1.25rem;font-weight:bold}#updates-wrapper #update-entries-wrapper .update-entry-section .update-entry-date{font-size:0.8rem;color:#5846f6;opacity:0.7;margin-bottom:0.5rem}#updates-wrapper #update-entries-wrapper .update-entry-section .update-entry-content{text-align:justify}

#about-wrapper{text-align:justify}#about-wrapper .headshot-avatar{min-width:250px;min-height:250px;width:50%;height:50%;margin:auto;border:1px solid #f5f5f7;margin-bottom:1rem;-webkit-transition:-webkit-transform 1s;transition:-webkit-transform 1s;transition:transform 1s;transition:transform 1s, -webkit-transform 1s}#about-wrapper:hover .headshot-avatar{-webkit-transform:rotateY(180deg);transform:rotateY(180deg)}#about-wrapper #my-linked-in-link{color:#5846f6 !important}#about-wrapper #my-linked-in-link:visited{color:#5846f6 !important}@media (max-width: 1060px){#about-wrapper .headshot-avatar{min-width:225px;min-height:225px}}

.job-card{padding:1rem 0;border-radius:6px}.job-card .job-card-content-wrapper{display:flex}.job-card .job-card-content-wrapper .job-icon-figure{margin-right:1rem !important}.job-card .job-card-content-wrapper .job-details-wrapper{flex-grow:1;padding-bottom:1rem;border-bottom:2px solid #f5f5f7}.job-card .job-card-content-wrapper .job-details-wrapper .job-position{color:#011ffd;font-weight:bold !important}.job-card .job-card-content-wrapper .job-details-wrapper .job-company{font-size:0.9rem}.job-card .job-card-content-wrapper .job-details-wrapper .job-date-and-location{font-size:0.85rem}.job-card .technologies{color:#011ffd;margin-bottom:0.25rem;margin-top:0.5rem;font-weight:bold}.job-card ul.main-technologies{margin:0 !important;padding:0 1rem !important;list-style-type:none}.job-card ul.main-technologies li>p::before{content:"- "}.job-icon-figure{margin:0 !important;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start}.job-icon-figure .job-icon{border-radius:50%;width:70px;height:70px}.job-icon-figure figcaption{visibility:hidden;height:0;width:0}

#experience-wrapper{width:100%;height:100%}



#app-wrapper{box-sizing:border-box;max-width:800px;padding:10px 20px;margin-left:auto;margin-right:auto;padding-top:65px}#app-wrapper main{padding-top:10px;opacity:0;position:relative;bottom:-25px;-webkit-transition:opacity .7s ease-out,bottom .7s;transition:opacity .7s ease-out,bottom .7s}#app-wrapper main.visible{opacity:1;bottom:0px}#app-wrapper .section-title{font-size:1.5rem;margin-bottom:10px;font-weight:bold}

body{margin:0px !important}.no-text-decoration{text-decoration:none !important}main.main-content-wrapper{min-height:60vh;width:80%;margin:auto;margin-top:65px !important;margin-bottom:100px !important;display:flex;flex-direction:column;justify-content:center}@media (max-width: 655px){main.main-content-wrapper{margin-top:120px !important}}@media (max-width: 355px){main.main-content-wrapper{margin-top:230px !important}}

