// Color scheme
$black: #212121;
$grey: #f5f5f7;
$blue: #5846f6;

$color-primary: #011ffd;
$color-secondary: #000000;
$pink: #f64c72;
$color-darker-secondary: #242582;
$color-brown: #553d67;
$color-soft-brown: #99738e;
$color-green: #7fba00;
$color-beige: #cccccc;
$color-grey: #e6ecf8;
$color-purple: #501f3a;
$color-white: #ffffff;

// Spacing
$spacing-small: 0.5rem;
$spacing-1: 0.75rem;
$spacing-2: 1rem;
$spacing-3: 1.25rem;
$spacing-4: 1.5rem;

// Static sizes:
$app-max-width: 800px;
$app-x-padding: 20px;
$app-y-padding: 10px;

$touch-area-min-size: 44px;

$app-bar-height: 65px;

// z-indices
$app-bar-z: 100;
$app-bar-expand-icon-z: 101;
$link-panel-z: 99;

// element specifics
$card-border-radius: 6px;

// Font
$font-family: Montserrat, sans-serif;
$section-title-font-size: 1.5rem;

// Transitions
$fade-in-duration: 0.7s;

:export {
  black: $black;
  grey: $grey;
  blue: $blue;

  colorPrimary: $color-primary;
  colorSecondary: $color-secondary;
  colorWhite: $color-white;
  colorGreen: $color-green;
  pink: $pink;
}

/* empty */
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

body {
  margin: 0px !important;
}

.no-text-decoration {
  text-decoration: none !important;
}

main.main-content-wrapper {
  min-height: 60vh;
  width: 80%;
  margin: auto;
  margin-top: $app-bar-height !important;
  margin-bottom: 100px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 655px) {
  main.main-content-wrapper {
    margin-top: 120px !important;
  }
}

@media (max-width: 355px) {
  main.main-content-wrapper {
    margin-top: 230px !important;
  }
}